import { observable, action, computed } from 'mobx'
import elasticsearch from 'elasticsearch'
import moment from 'moment'
import { urlToSearchState, searchStateToUrl } from '../core/searchState'

class _ElasticStore {
  @observable
  searchTerm: string = ''

  querying: boolean = false

  @observable
  hits: any[] = []

  @observable
  hoveredElement = {}

  filters = observable.map()

  @observable
  metrics = { count: 0, avg: 0 }

  @observable
  resultsTotal: number = 0

  @observable
  grades: any[] = []

  @observable
  sales_per_day: any[] = []

  @observable
  sales_by_cert: any[] = []

  @observable
  prices_per_day: any[] = []

  private client

  constructor() {
    this.client = new elasticsearch.Client({
      host: 'https://adb7b98c8fb04b6c94d4eba75d4d1753.us-central1.gcp.cloud.es.io:9243',
      httpAuth: 'elastic:3WYjiCgGXZlkc6haXwxFGOSf',
    })
  }

  @computed
  get searchState() {
    return { searchTerm: this.searchTerm, filters: this.filters.toJSON() }
  }

  @action
  setSearchState(location) {
    const searchState = urlToSearchState(location)
    this.filters.replace(searchState.filters || {})
    this.searchTerm = searchState.searchTerm || ''

    if (searchState.searchTerm != undefined) {
      this.query()
    } else {
      this.hits = []
      this.filters.clear()
      this.metrics = { count: 0, avg: 0 }
      this.resultsTotal = 0
      this.sales_by_cert = []
      this.sales_per_day = []
      this.prices_per_day = []
    }
  }

  @action
  async query() {
    if (this.querying) return
    this.querying = true
    let term = this.searchTerm
    const terms = term.replace(/#/g, '').split(' ')
    const selectedGrade = this.filters.get('grade')

    const results = await this.client.search({
      index: 'comicpricecheck',
      type: 'sales',
      body: {
        size: selectedGrade ? 50 : 0,
        _source: ['title', 'id', 'price', 'endTime'],
        query: {
          constant_score: {
            filter: {
              bool: {
                must: {
                  span_near: {
                    clauses: buildClauses(terms),
                    slop: 1,
                    in_order: true,
                  },
                },
                filter: this.buildFilters(),
                should: {
                  range: {
                    endTime: { gte: moment().subtract(90, 'days') },
                  },
                },
                must_not: [
                  { terms: { title: this.buildRemovalTerms(terms) } },
                  this.checkForUnknownGrade(),
                ],
              },
            },
          },
        },
        aggs: this.buildAggs(selectedGrade),

        sort: {
          endTime: {
            order: 'desc',
          },
        },
      },
    })

    this.resultsTotal = results.hits.total
    this.metrics = results.aggregations.price_stats
    this.hits = results.hits.hits

    if (results.aggregations.grades) {
      const grades = results.aggregations.grades.buckets.reduce((grades, grade) => {
        const newGrade = grade
        newGrade.key = Math.round(grade.key * 10) / 10
        grades.push(newGrade)
        return grades
      }, [])

      this.grades = grades
    }

    if (results.aggregations.sales_by_cert) {
      this.sales_by_cert = results.aggregations.sales_by_cert.buckets
    }

    const oldTerm = this.searchTerm.toLowerCase()
    const newTerm = term.toLowerCase()

    if (oldTerm != newTerm && newTerm != '') {
      this.client.index({
        index: 'searches',
        type: 'searches',
        body: {
          searchTerm: newTerm,
          date: moment(),
        },
      })
    }

    this.searchTerm = newTerm
    this.querying = false
  }

  buildAggs(selectedGrade) {
    const aggs: any = { price_stats: { extended_stats: { field: 'price' } } }
    let sales_by_cert
    let grades

    if (selectedGrade) {
      sales_by_cert = {
        terms: {
          field: 'certification',
        },

        aggs: {
          sales_per_day: {
            date_histogram: {
              field: 'endTime',
              interval: 'day',
              min_doc_count: 0,
              extended_bounds: {
                min: moment().subtract(90, 'days'),
                max: moment(),
              },
            },
            aggs: {
              price_stats: { extended_stats: { field: 'price' } },
            },
          },
        },
      }
    } else {
      grades = {
        terms: {
          field: 'grade',
          order: {
            _key: 'desc',
          },
          missing: 100,
        },
        aggs: {
          sales_per_day: {
            date_histogram: {
              field: 'endTime',
              interval: 'day',
            },
            aggs: {
              price_stats: { extended_stats: { field: 'price' } },
            },
          },
          price_stats: { extended_stats: { field: 'price' } },
        },
      }
    }

    return { ...aggs, grades, sales_by_cert }
  }

  checkForUnknownGrade() {
    if (this.filters.get('grade') == 100) {
      return { exists: { field: 'grade' } }
    } else {
      return { exists: { field: 'fake' } }
    }
  }

  buildFilters() {
    const filters = Array.from(this.filters)
      .filter(([key, value]) => {
        return !(key == 'grade' && value == '100')
      })
      .map(([key, value]) => {
        return { term: { [key]: value } }
      })

    return filters
  }

  @action
  setFilters(filters) {
    if (!filters && filters != '') return
    this.filters.clear()
    const keys = Object.keys(filters)
    keys.map(key => this.filters.set(key, filters[key]))
  }

  @action
  removeFilter(key) {
    this.filters.delete(key)
    this.query()
  }

  @action
  clearFilters() {
    this.filters.clear()
  }

  @action
  addFilter({ key, value }) {
    this.filters.set(key, value)
    this.query()
  }

  @action
  toggleFilter({ key, value }) {
    if (this.filters.has(key)) {
      if (this.filters.get(key) == value) {
        this.filters.delete(key)
      } else {
        this.filters.set(key, value)
      }
    } else {
      this.filters.set(key, value)
    }
    this.query()
  }

  buildRemovalTerms(terms) {
    const removalTerms: string[] = []
    terms.map(term => {
      if (isNaN(parseInt(term))) {
        if (term.split('')[0] == '-') {
          removalTerms.push(term.replace('-', ''))
        }
      }
    })

    return removalTerms
  }
}

function buildClauses(terms) {
  const clauses: any[] = []
  terms.filter(term => term.split('')[0] != '-').map(term => {
    if (isNaN(parseInt(term))) {
      if (term.length < 3) {
        const staticClause = {
          span_term: { title: `${term}` },
        }
        clauses.push(staticClause)
      } else {
        const fuzzyClause = {
          span_multi: {
            match: {
              fuzzy: {
                title: {
                  fuzziness: 1,
                  value: term,
                },
              },
            },
          },
        }
        clauses.push(fuzzyClause)
      }
    } else {
      const clause = {
        span_first: {
          match: {
            span_term: { title: `${term}` },
          },
          end: 4,
        },
      }
      clauses.push(clause)
    }
  })

  return clauses
}
export const ElasticStore = new _ElasticStore()
