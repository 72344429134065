import * as React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { ElasticStore } from '../../stores/ElasticStore'
import { H3, H4, Stack, Frame } from '../UI'
import { Sparklines, SparklinesLine, SparklinesNormalBand } from 'react-sparklines'
import { ComicRow } from '../ComicRow'
import { theme } from '../../core/theme'
import { searchStateToUrl, urlToSearchState } from '../../core/searchState'
import { withRouter } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'

interface gradesProps extends RouteComponentProps<{ history: any; location: any }> {
  location: any
  history: any
}

@observer
class _Grades extends React.Component<gradesProps> {
  handleSelectGrade = grade => {
    const searchState = urlToSearchState(this.props.location)
    searchState.filters = { grade, ...searchState.filters }
    this.props.history.push(searchStateToUrl(this.props, searchState), searchState)
  }

  render() {
    if (ElasticStore.grades.length == 0) return null
    return (
      <GradeList>
        {ElasticStore.grades.map(grade => {
          const gradePrices = grade.sales_per_day.buckets.reduce((prices, sale) => {
            if (sale.price_stats.avg) {
              prices.push(sale.price_stats.avg)
            }
            return prices
          }, [])

          const prices = { start: gradePrices[0], end: gradePrices[gradePrices.length - 1] }
          const trendingUp = prices.end > prices.start
          let trendColor = trendingUp ? theme.green : theme.darkRed
          const percentDifference =
            Math.round(
              ((Math.max(prices.start, prices.end) - Math.min(prices.start, prices.end)) /
                prices.end) *
                10000,
            ) / 100

          if (percentDifference == 0) trendColor = theme.grey300
          let gradeValue: any = Math.round(grade.key * 100) / 100
          if (gradeValue == 100) gradeValue = 'Unknown'

          return (
            <Grade
              key={`grade_${grade.key}`}
              onClick={this.handleSelectGrade.bind(this, grade.key)}
            >
              <Stack padding="16px">
                <Frame>
                  <H3>{gradeValue}</H3>
                  <H4>Grade</H4>
                </Frame>
                <Frame flex align="center" justify="center">
                  <Sparkline>
                    <Sparklines data={gradePrices}>
                      <SparklinesLine
                        style={{ stroke: trendColor, strokeWidth: 3, fill: 'none' }}
                      />
                    </Sparklines>
                  </Sparkline>
                </Frame>
                <Frame align="flex-end">
                  <H3 color={trendColor}>${Math.round(grade.price_stats.avg * 100) / 100}</H3>
                  {percentDifference != 0 && (
                    <H4 color={trendColor}>
                      {trendingUp ? '+' : '-'} {percentDifference}%
                    </H4>
                  )}
                </Frame>
              </Stack>
            </Grade>
          )
        })}
        {ElasticStore.grades.length == 1 && (
          <React.Fragment>
            {/* <Candlestick /> */}
            {ElasticStore.hits.map(({ _source, _score }) => (
              <ComicRow key={_source.id} hit={_source} />
            ))}
          </React.Fragment>
        )}
      </GradeList>
    )
  }
}

const GradeList = styled.div`
  width: 100%;
`
const Grade = styled.div`
  background-color: white;
  border-bottom: 1px solid ${props => props.theme.grey800};
`

const Sparkline = styled.div`
  width: 150px;
`

export const Grades = withRouter(_Grades)
