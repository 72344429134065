import * as React from 'react'
import ReactEchartsCore from 'echarts-for-react/lib/core'
import { ElasticStore } from '../../stores/ElasticStore'
import echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/title'
import 'echarts/lib/component/tooltip'
import { observer } from 'mobx-react'
import { theme } from '../../core/theme'

@observer
export class GradeHistogram extends React.Component {
  state = { grade: '0.0' }
  getOption = () => {
    const gradeArray = ElasticStore.grades
      .filter(grade => grade.key != 100)
      .slice()
      .reverse()

    const gradeLabels = gradeArray.reduce((grades, grade) => {
      grades.push(Math.round(grade.key * 10) / 10)
      return grades
    }, [])

    const gradeCounts = gradeArray.reduce((counts, grade) => {
      counts.push(grade.doc_count)
      return counts
    }, [])

    const gradePrices = gradeArray.reduce((prices, grade) => {
      prices.push(grade.price_stats.avg)
      return prices
    }, [])

    return {
      tooltip: {
        trigger: 'axis',
        formatter: params => {
          this.setState({ grade: params[0].name })
        },
      },
      legend: {
        data: gradeLabels,
      },

      grid: {
        top: '0px',
        left: '16px',
        right: '16px',
        containLabel: false,
      },
      xAxis: [{ data: gradeLabels }],
      yAxis: [{ type: 'value', show: false }],
      series: [
        {
          name: 'Number of sales',
          type: 'bar',
          color: theme.primary,
          data: gradeCounts,
        },
      ],
    }
  }
  render() {
    if (ElasticStore.grades.length < 3) return null
    return (
      <div onClick={() => ElasticStore.toggleFilter({ key: 'grade', value: this.state.grade })}>
        <ReactEchartsCore
          echarts={echarts}
          option={this.getOption()}
          style={{ height: '200px', width: '100%' }}
          theme="theme"
          className="react_for_echarts"
        />
      </div>
    )
  }
}
