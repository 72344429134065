import React from 'react'
import { observer } from 'mobx-react'
import { ElasticStore } from '../stores/ElasticStore'
import styled from 'styled-components'
import { SearchInput } from '../components/SearchInput'

interface searchProps {
  location: string
  history: { push(url: string, state?: string): void }
}

@observer
export class Landing extends React.Component<searchProps> {
  constructor(props) {
    super(props)
    ElasticStore.setSearchState(props.location)
  }

  componentWillReceiveProps(props) {
    if (props.location !== this.props.location) {
      ElasticStore.setSearchState(props.location)
    }
  }

  render() {
    const { searchTerm } = ElasticStore

    return (
      <React.Fragment>
        <Logo>
          <img src={require('../assets/logo.svg')} />
        </Logo>
        <SearchContainer>
          <SearchInput hasResults={false} />

          <Tip>
            <div>
              <strong>Tip:</strong> Search title and issue number
            </div>
            <img src={require('../assets/tips_search.png')} />
          </Tip>
        </SearchContainer>
      </React.Fragment>
    )
  }
}

interface searchProps {
  searchTerm?: string
  hasResults?: boolean
}

const Logo = styled.div`
  width: 200px;
  position: absolute;
  top: 20%;
  transition: all 0.5s ease;
  max-width: 240px;
  img {
    width: 100%;
  }
`

const Tip = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  align-items: center;
  width: 100%;
  font-size: 14px;
  max-width: 400px;

  img {
    margin-top: 8px;
    width: 80%;
  }
`

const SearchContainer = styled.div`
  background-color: #fafafa;
  position: absolute;
  padding: 0 16px;
  width: 100%;
  height: 56px;
  top: 40%;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  align-content: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 0;
`
