import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Layout } from './views/Layout'
import withTracker from './core/withTracker'

const App = () => (
  <Router>
    <Route path="/" component={withTracker(Layout)} />
  </Router>
)

ReactDOM.render(<App />, document.getElementById('appRoot'))
