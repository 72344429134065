import * as React from 'react'
import styled from 'styled-components'

export const Card = styled.div`
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  padding: 16px;
  width: 100%;
  position: relative;
  border-radius: 6px;
  z-index: 3;
`

interface stackProps {
  padding: string
  justify: string
  align: string
  height?: string
}

export const Stack = styled<stackProps, any>('div')`
  display: flex;
  height: ${props => props.height || '100%'};
  padding: ${props => props.padding || '0px'};
  justify-content: ${props => props.justify || 'start'};
  align-items: ${props => props.align || 'start'};
`

interface frameProps {
  flex?: boolean
  center?: boolean
  align?: string
  justify?: string
}

export const Frame = styled<frameProps, any>('div')`
  flex: ${props => (props.flex ? 1 : 'none')};
  display: flex;
  flex-direction: column;
  align-items: ${props => props.align || (props.center && 'center')};
  justify-content: ${props => props.justify || (props.center && 'center')};
`

///////////////////////////////
// TYPOGRAPHY                 /
///////////////////////////////

interface typographyProps {
  fontColor?: string
  pointer?: boolean
}

export const H1 = styled<typographyProps, any>('div')`
  font-size: 64px;
  letter-spacing: -0.05em;
  color: ${props => props.fontColor || props.theme.green};
`

export const H2 = styled<typographyProps, any>('div')`
  font-size: 68px;
  letter-spacing: -2px;
  font-weight: bold;
  color: ${props => props.fontColor || props.theme.green};

  sup {
    font-size: 32px;
    font-weight: 400;
  }
`

export const H3 = styled<typographyProps, any>('div')`
  font-size: 20px;
  letter-spacing: -0.03em;
  font-family: ${props => props.theme.fontFamily};
  font-weight: bold;
  color: ${props => props.fontColor || props.theme.grey300};
  cursor: ${props => (props.pointer ? 'pointer' : 'normal')};
`
export const H4 = styled<typographyProps, any>('div')`
  font-size: 12px;
  font-family: ${props => props.theme.fontFamily};
  color: ${props => props.fontColor || props.theme.grey500};
  text-transform: uppercase;
`

export const H5 = styled<typographyProps, any>('div')`
  font-size: 10px;
  font-family: ${props => props.theme.fontFamily};
  color: ${props => props.fontColor || props.theme.grey500};
  text-transform: uppercase;
`
