import * as React from 'react'
import { ElasticStore } from '../stores/ElasticStore'
import { searchStateToUrl } from '../core/searchState'
import styled from 'styled-components'
import { Icon } from 'rmwc/icon'
import { withRouter } from 'react-router-dom'
import { SelectedFilters } from '../components/SelectedFilters'
import { OverviewCard } from '../components/charts'
import { observer } from 'mobx-react'

interface props {
  history?: any
  location?: any
  hasResults: boolean
}

@(withRouter as any)
@observer
export class SearchInput extends React.Component<props> {
  private searchInput

  constructor(props) {
    super(props)
    this.searchInput = React.createRef()
  }

  updateSearchTerm = ({ target }) => {
    ElasticStore.searchTerm = target.value
  }

  clearForm = () => {
    this.props.history.push('/')
  }

  executeSearch = event => {
    event.preventDefault()
    ElasticStore.searchTerm = this.searchInput.current.value
    const basePath = this.props.location.pathname == '/' ? 'search' : ''
    this.props.history.push(basePath + searchStateToUrl(this.props, ElasticStore.searchState))
  }

  componentDidMount() {
    this.searchInput.current.focus()
  }

  render() {
    const gradeIsSelected = ElasticStore.filters.get('grade')

    return (
      <SearchBar hasResults={this.props.hasResults}>
        <SearchBarForm hasResults={this.props.hasResults} onSubmit={this.executeSearch}>
          <SearchBox
            innerRef={this.searchInput}
            hasResults={this.props.hasResults}
            onChange={this.updateSearchTerm}
            value={ElasticStore.searchTerm}
            placeholder="Search Amazing Spiderman 300 -homage"
          />

          {!this.props.hasResults && (
            <SubmitButton hasResults={this.props.hasResults}>
              <Icon icon="search" />
            </SubmitButton>
          )}
          {this.props.hasResults && (
            <Icon icon="close" onClick={this.clearForm} style={{ cursor: 'pointer' }} />
          )}
        </SearchBarForm>
        {gradeIsSelected && <OverviewCard />}
        {this.props.hasResults && <SelectedFilters />}
      </SearchBar>
    )
  }
}

interface searchProps {
  searchTerm?: string
  hasResults?: boolean
}

const SearchBar = styled<searchProps, any>('div')`
  box-shadow: ${props => (props.hasResults ? 'none' : '0 12px 24px rgba(20, 64, 118, 0.12)')};
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  max-width: 1100px;
  width: 100%;
  background-color: ${props => (props.hasResults ? 'none' : 'white')};
  border-radius: 6px;

  height: 100%;
  position: relative;
`

const SearchBarForm = styled<searchProps, any>('form')`
  height: 56px;
  padding: 0 ${props => (props.hasResults ? '16px' : '0px')} 0 16px;
  font-size: 12px !important;
  transition: all 0.4s ease;
  display: flex;
  align-items: center;
  z-index: 3;
  color: white;

  Icon {
    color: ${props => (props.hasResults ? 'white' : props.theme.primary)};
  }
`

const SearchBox = styled<searchProps, any>('input')`
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: ${props => (props.hasResults ? 'white' : props.theme.primary)};
  border: none;
  font-family: 'Exo 2', 'sans serif';
  font-size: ${props => (props.hasResults ? 18 : 16)}px;
  font-weight: ${props => (props.hasResults ? 600 : 400)};

  &:focus {
    border: none;
    outline: none;
  }

  &::placeholder {
    color: ${props => (props.hasResults ? 'rgba(255, 255, 255, 0.5)' : '#cecece')};
  }
`

const SubmitButton = styled<searchProps, any>('button')`
  position: relative;
  transform: translateX(${props => (props.hasResults ? '48px' : '0px')});
  width: 56px;
  background-color: var(--mdc-theme-primary);
  background-image: linear-gradient(135deg, #6967d8 0%, #58a4ff 100%);
  color: white;
  border-radius: 0 8px 8px 0;
  height: 100%;
  transition: all 0.5s ease;
  border: none;
`
