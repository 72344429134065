import { observable, action, computed } from 'mobx'
// import { firestore } from '../core/Firebase'

class _Store {
  @observable
  isMobile: boolean = window.innerWidth < 800

  @observable
  hideOutliers: boolean = true

  @observable
  drawerOpened: boolean = false

  @observable
  flagged: string[] = []

  @observable
  outliers: string[] = []

  @computed
  get hiddenHits() {
    return this.flagged.concat(this.outliers)
  }

  @observable
  searchQuery: string

  @observable
  selectedComic: { id; title?; listingType?; endTime?; price?; viewItemURL? }

  constructor() {
    this.init()
    this.selectedComic = { id: null }
  }

  @action
  init = () => {
    this.searchQuery = ''
  }

  @action
  updateWidth = () => {
    this.isMobile = window.innerWidth < 800
  }

  @action
  openDrawer = () => {
    this.drawerOpened = true
  }

  @action
  closeDrawer = () => {
    this.drawerOpened = false
  }

  @action
  addToFlagged = id => {
    if (this.flagged.find(item => item == id)) {
      this.flagged = this.flagged.filter(item => item != id)
    } else {
      this.flagged.push(id)
    }
  }

  @action
  addArrayToFlagged = array => {
    this.flagged.concat(array)
  }

  @action
  setQuery = (query: string) => {
    if (this.searchQuery != query && query != '' && query) {
    }

    this.searchQuery = query || ''
  }

  @action
  toggleOutliers = () => {
    this.hideOutliers = !this.hideOutliers
  }
}

export const Store = new _Store()
