import * as React from 'react'
import { Route } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { Store } from '../stores/Store'
import { Index } from './Index'
import { theme } from '../core/theme'

import '../assets/reset.scss'
import '../assets/style.scss'
import '../../node_modules/material-components-web/dist/material-components-web.min.css'

export class Layout extends React.Component<any> {
  componentWillMount() {
    window.addEventListener('resize', Store.updateWidth)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', Store.updateWidth)
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <Route path="/" component={Index} />
        </React.Fragment>
      </ThemeProvider>
    )
  }
}
