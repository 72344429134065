import React from 'react'
import { observer } from 'mobx-react'
import { ElasticStore } from '../stores/ElasticStore'
import { Grades, PricesPerDay, GradeHistogram } from '../components/charts'
import styled from 'styled-components'
import { ComicRow } from '../components/ComicRow'
import { searchStateToUrl } from '../core/searchState'
import { SearchInput } from '../components/SearchInput'

interface searchProps {
  location: string
  history: { push(url: string, state?: string): void }
}

@observer
export class Search extends React.Component<searchProps> {
  private searchInput

  constructor(props) {
    super(props)
    this.searchInput = React.createRef()
  }

  updateSearchTerm = ({ target }) => {
    ElasticStore.searchTerm = target.value
  }

  clearForm = event => {
    event.preventDefault()
    this.props.history.push('/')
  }

  executeSearch = event => {
    event.preventDefault()
    ElasticStore.searchTerm = this.searchInput.current.value
    this.props.history.push('/search' + searchStateToUrl(this.props, ElasticStore.searchState))
  }

  render() {
    const gradeIsSelected = ElasticStore.filters.get('grade') != undefined
    return (
      <React.Fragment>
        <SearchContainer gradeIsSelected={gradeIsSelected}>
          <SearchInput hasResults={true} />
        </SearchContainer>

        <Content gradeIsSelected={gradeIsSelected}>
          <Container>
            {!gradeIsSelected && (
              <React.Fragment>
                <GradeHistogram />
                <Grades />
              </React.Fragment>
            )}
            {gradeIsSelected && (
              <React.Fragment>
                <PricesPerDay />
                {ElasticStore.hits.map(({ _source }) => (
                  <ComicRow key={_source.id} hit={_source} />
                ))}
              </React.Fragment>
            )}
          </Container>
        </Content>
      </React.Fragment>
    )
  }
}

interface searchProps {
  gradeIsSelected: boolean
}

const Content = styled<searchProps, any>('div')`
  margin-top: ${props => (props.gradeIsSelected ? 350 : 180)}px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
`

const Container = styled.div`
  width: 100%;
  max-width: 1100px;
`

const SearchContainer = styled<searchProps, any>('div')`
  background-color: ${props => props.theme.primary};
  position: absolute;
  width: 100%;
  height: ${props => (props.gradeIsSelected ? 305 : 128)}px;
  top: 0;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  align-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 0;
`
