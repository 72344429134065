import * as React from 'react'
import { observer } from 'mobx-react'
import { ElasticStore } from '../stores/ElasticStore'
import styled from 'styled-components'
import { SimpleMenu, MenuItem } from 'rmwc/menu'
import { H3, H5, Stack, Frame } from './UI'
import { theme } from '../core/theme'
import { searchStateToUrl, urlToSearchState } from '../core/searchState'
import { RouteComponentProps } from 'react-router-dom'
import { withRouter } from 'react-router'

interface selectedFiltersProps extends RouteComponentProps<{ history: any; location: any }> {
  location: any
  history: any
}

@observer
class _SelectedFilters extends React.Component<selectedFiltersProps> {
  render() {
    const grades = ElasticStore.grades.slice()
    let gradeKeys: string[] = []
    gradeKeys = grades.reduce((array, grade) => {
      array.push(grade.key.toString())
      return array
    }, [])

    gradeKeys.splice(0, 0, 'All')

    return (
      <FilterWrapper>
        <FilterCard>
          <Stack justify="space-around" align="center" padding="16px">
            <Frame>
              <FilterSelect
                title="Reprints"
                filterKey="reprint"
                options={['All', 'true', 'false']}
                optionTitles={['All', 'Only', 'Hide']}
              />
            </Frame>
            <Frame>
              <FilterSelect
                title="Variants"
                filterKey="variant"
                options={['All', 'true', 'false']}
                optionTitles={['All', 'Only', 'Hide']}
              />
            </Frame>
            <Frame>
              <FilterSelect
                title="Signature"
                filterKey="SS"
                options={['All', 'true', 'false']}
                optionTitles={['All', 'Only', 'Hide']}
              />
            </Frame>
            <Frame>
              <FilterSelect
                title="Certification"
                filterKey="certification"
                options={['All', 'raw', 'CGC']}
                optionTitles={['All', 'Raw', 'CGC']}
              />
            </Frame>
          </Stack>
        </FilterCard>
      </FilterWrapper>
    )
  }
}

export const SelectedFilters = withRouter(_SelectedFilters)

const FilterWrapper = styled.div`
  padding: 0 16px;
  max-width: 1100px;
  width: 100%;
  bottom: -32px;
  position: absolute;
`

const FilterCard = styled.div`
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  height: 64px;
  text-align: center;
`

///
interface filterProps extends RouteComponentProps<{ history: any; location: any }> {
  location: any
  history: any
  match: any
  filterKey: string
  title: string
  options: string[]
  optionTitles: string[]
}

@observer
class _FilterSelect extends React.Component<filterProps> {
  handleFilterSelect = event => {
    const index = event.detail.index
    if (index == 'undefined') return

    const value = this.props.options[index]
    const key = this.props.filterKey

    const searchState = urlToSearchState(this.props.location)

    if (!searchState.filters) searchState.filters = []
    if (value == 'All') {
      delete searchState.filters[key]
    } else {
      searchState.filters[key] = value
    }
    this.props.history.push(searchStateToUrl(this.props, searchState), searchState)
  }

  render() {
    let selectedFilter = 'All'
    const storeValue = ElasticStore.filters.get(this.props.filterKey)

    if (storeValue != null) {
      const valueIndex = this.props.options.indexOf(storeValue.toString())
      selectedFilter = this.props.optionTitles[valueIndex]
    }

    if (selectedFilter == undefined) selectedFilter = storeValue

    return (
      <React.Fragment>
        <SimpleMenu
          handle={
            <H3 color={theme.primary} pointer={true}>
              {selectedFilter}
            </H3>
          }
          onSelect={this.handleFilterSelect}
        >
          {this.props.options.map((option, index) => (
            <MenuItem key={`${option}_${index}`} style={{ cursor: 'pointer' }}>
              {this.props.optionTitles[index]}
            </MenuItem>
          ))}
        </SimpleMenu>
        <H5>{this.props.title}</H5>
      </React.Fragment>
    )
  }
}

const FilterSelect = withRouter(_FilterSelect)
