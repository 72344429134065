import * as React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Store } from '../stores/Store'
import { Link } from 'react-router-dom'
const storageURL = 'https://storage.googleapis.com/comicmarketwatch.appspot.com'

interface Props {
  hit: any
}

interface State {
  loading: boolean
  imageMissing: boolean
}

@observer
export class ComicRow extends React.Component<Props, State> {
  state = { loading: false, imageMissing: false }

  replaceImage = async id => {
    if (this.state.imageMissing) return

    this.setState({ loading: true, imageMissing: true })
    await fetch(
      'https://us-central1-comicmarketwatch.cloudfunctions.net/uploadImageToStorage?id=' + id,
      {
        mode: 'no-cors',
      },
    )

    setTimeout(() => {
      this.setState({ loading: false })
    }, 100)
  }

  hasImage = () => {
    this.setState({ loading: false, imageMissing: false })
  }

  render() {
    const { hit } = this.props
    const originalURL = hit.viewItemURL + '?nordt=true'
    const imagePath = `${storageURL}/images/image_${hit.id}.jpg?${Date.now()}`
    return (
      <ComicRowStyle key={hit.id} hidden={Store.hiddenHits.includes(hit.id)}>
        <Thumbnail>
          <LazyLoadImage height={150} src={imagePath} />
          {/* <img
            src={imagePath}
            onError={this.replaceImage.bind(this, hit.id)}
            onLoad={this.hasImage.bind(this)}
          /> */}
        </Thumbnail>

        <Details>
          <Row>
            <Col>
              <Endtime>
                <span>
                  {moment(hit.endTime)
                    .utc()
                    .format('MMM Do, YYYY')}
                </span>
                {/* <Remove onClick={() => Store.addToFlagged(hit.id)}>Remove</Remove> */}
              </Endtime>
              <Title>{hit.title}</Title>
              <React.Fragment>
                <ListingType>{hit.listingType}</ListingType>
                <Price target="_blank">${hit.price}</Price>
              </React.Fragment>
            </Col>
          </Row>
        </Details>
      </ComicRowStyle>
    )
  }
}
interface styleProps {
  heightProp: string
  hidden: boolean
}
const ComicRowStyle = styled<styleProps, any>('div')`
  width: 100%;
  /* opacity: ${props => (props.hidden ? 0.35 : 1)}; */
  display: ${props => (props.hidden ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.12);
  margin: 0px 0;
  background-color: white;
  overflow: hidden;
`

const Thumbnail = styled.div`
  width: 90px;
  height: 150px;
  background-color: #333;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`

const Details = styled.div`
  flex: 1;
  padding: 0 16px;
`

const ListingType = styled.div`
  font-size: 9px;
  color: #aaa;
`

const Endtime = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  color: ${props => props.theme.grey500};
  font-size: 11px;
  margin-bottom: 4px;
  display: flex;
  align-items: flex-end;
  span {
    flex: 1;
  }
`
const Remove = styled.div`
  font-size: 10px;
  cursor: pointer;
  /* color: ${props => props.theme.grey800}; */
`

const Title = styled.div`
  font-size: 12px;
  margin-bottom: 8px;
  color: ${props => props.theme.text};
  font-weight: bold;
  line-height: 1.2;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Price = styled.a`
  font-size: 18px;
  color: ${props => props.theme.green} !important;
  font-weight: bold;
`

const Upgrade = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.green};
  text-transform: uppercase;
  border: 1px solid ${props => props.theme.green};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 4px 0;
`

const OriginalListing = styled.a`
  height: 24px;
  width: 140px;
  margin-top: 8px;
  font-size: 11px;
  border: 1px solid var(--mdc-theme-light);
  color: var(--mdc-theme-light);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    color: var(--mdc-theme-primary);
    border-color: var(--mdc-theme-primary);
  }

  &:visited {
    color: var(--mdc-theme-light);
  }
`
