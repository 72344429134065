export const theme = {
  // primary: '#58A3FE',
  primary: '#6184EB',
  secondary: '#5968E2',
  fontFamily: 'Exo 2',
  text: '#5B8394',
  grey800: '#E6EBF1',
  grey600: '#dedede',
  grey500: '#BBBBBB',
  grey300: '#4d4d4d',
  green: '#24B47E',
  darkRed: '#C25656',
  error: '#D64720',
}
