import React from 'react'
import { observer } from 'mobx-react'
import { ElasticStore } from '../stores/ElasticStore'
import { Route } from 'react-router-dom'
import { Landing } from './Landing'
import { Search } from './Search'

interface searchProps {
  location: string
  history: { push(url: string, state?: string): void }
}

@observer
export class Index extends React.Component<searchProps> {
  constructor(props) {
    super(props)
    ElasticStore.setSearchState(props.location)
  }

  componentWillReceiveProps(props) {
    if (props.location !== this.props.location) {
      ElasticStore.setSearchState(props.location)
    }
  }

  render() {
    return (
      <React.Fragment>
        <Route exact path="/" component={Landing} />
        <Route exact path="/search" component={Search} />
      </React.Fragment>
    )
  }
}
