import * as React from 'react'
import ReactEchartsCore from 'echarts-for-react/lib/core'
import echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/title'
import 'echarts/lib/component/tooltip'
import moment from 'moment'
import { ElasticStore } from '../../stores/ElasticStore'
import { observer } from 'mobx-react'
import { theme } from '../../core/theme'

@observer
export class PricesPerDay extends React.Component {
  getOption = () => {
    const salesValues: any[] = []
    const salesDates: any[] = []
    const salesPerDay: any[] = []

    ElasticStore.sales_by_cert.map(sales => {
      const key = sales.key
      if (key == 'CBCS' || key == 'PGX') return

      let lastSale = 0
      const saleAverages = sales.sales_per_day.buckets.reduce((averages, sale) => {
        averages.push(sale.price_stats.avg || lastSale)
        lastSale = sale.price_stats.avg || lastSale
        return averages
      }, [])

      sales.sales_per_day.buckets.map(sale => {
        if (sales == ElasticStore.sales_by_cert[0])
          salesDates.push(
            moment(sale.key)
              .utc()
              .format('MM/DD'),
          )
      })

      const saleCounts = sales.sales_per_day.buckets.reduce((counts, sale) => {
        counts.push(sale.doc_count)
        return counts
      }, [])

      salesPerDay.push({
        name: key,
        color: key == 'CGC' ? theme.primary : theme.secondary,
        type: 'bar',
        data: saleCounts,
        stack: 'one',
        xAxisIndex: 1,
        yAxisIndex: 1,
      })

      salesValues.push({
        name: key,
        type: 'line',
        symbol: 'none',
        color: key == 'CGC' ? theme.primary : theme.secondary,
        data: saleAverages,
        areaStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: key == 'CGC' ? 'rgba(88, 163, 254, 0.5)' : 'rgba(89, 104, 226, 0.5)',
              },
              {
                offset: 1,
                color: key == 'CGC' ? 'rgba(88, 163, 254, 0.1)' : 'rgba(89, 104, 226, 0.1)',
              },
            ]),
          },
        },
        connectNulls: true,
      })
    })

    return {
      tooltip: {
        trigger: 'axis',
      },
      grid: [
        { left: '0', right: '0', top: '0%', bottom: '55%' },
        { left: '0', right: '0', top: '55%', bottom: '10%' },
      ],
      xAxis: [{ data: salesDates, splitLine: { show: false } }, { data: salesDates, gridIndex: 1 }],
      yAxis: [{ type: 'value', show: false }, { type: 'value', show: false, gridIndex: 1 }],
      series: salesValues.concat(salesPerDay),
      option: true,
    }
  }

  render() {
    return (
      <ReactEchartsCore
        echarts={echarts}
        option={this.getOption()}
        notMerge={true}
        style={{ height: '400px', width: '100%' }}
        theme="theme"
        className="react_for_echarts"
      />
    )
  }
}
