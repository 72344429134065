import * as React from 'react'
import { observer } from 'mobx-react'
import { ElasticStore } from '../../stores/ElasticStore'
import styled from 'styled-components'
import { Icon } from 'rmwc/Icon'
import { H2, H3, H4, Frame, Stack } from '../UI'
import { searchStateToUrl, urlToSearchState } from '../../core/searchState'
import { withRouter } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'

interface overviewProps extends RouteComponentProps<{ history: any; location: any }> {
  location: any
  history: any
}

@observer
class _OverviewCard extends React.Component<overviewProps> {
  handleBackClick = () => {
    ElasticStore.filters.delete('grade')
    const searchState = urlToSearchState(this.props.location)
    delete searchState.filters.grade
    this.props.history.push(searchStateToUrl(this.props, searchState), searchState)
  }
  render() {
    let selectedGrade = ElasticStore.filters.get('grade')
    if (selectedGrade == 100) selectedGrade = 'Unknown'
    const avgPrice = Math.round(ElasticStore.metrics.avg * 100) / 100
    const totalCount = ElasticStore.metrics.count

    return (
      <OverviewCardStyle>
        <BackButton onClick={this.handleBackClick}>
          <Icon icon="arrow_back" />
        </BackButton>
        <Hero>
          <H4 fontColor="rgba(255, 255, 255, 0.48)">90 day average</H4>
          <H2 fontColor="white">
            <sup>$</sup>
            {avgPrice}
          </H2>
          <H3 fontColor="rgba(255, 255, 255, 0.8)">
            Found {totalCount} sales at {selectedGrade}
          </H3>
        </Hero>
      </OverviewCardStyle>
    )
  }
}

const OverviewCardStyle = styled.div`
  margin: 32px 0;
  position: relative;
  color: white;
`

const Hero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
`

const BackButton = styled.div`
  position: absolute;
  top: 0;
  left: 16px;
  cursor: pointer;
`

export const OverviewCard = withRouter(_OverviewCard)
